import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { css } from "@emotion/core"
import { Box, Flex } from "reflexbox"

import Layout from "~/components/common/Layout"

import { Heading, Text, Ul } from "~/components/common/Typography"
import HeroVideo from "~/components/page/HeroVideo"
import { InfoboxDetailed } from "~/components/page/Infobox"
import {
  getBackLink,
  getHeaderTitles,
  getNextLink,
  getNextTitle,
} from "~/services/pages"
import withProgress from "~/services/withProgress"
import Copyright from "~/components/common/Copyright"
import Img from "gatsby-image"
import Source from "~/components/common/Source"
import { mq } from "~/components/common/theme"
import Button from "~/components/common/Button"

const mqMobile = mq({
  display: ["block", "none"],
})
const mqNonMobile = mq({
  display: ["none", "block"],
})

const Table = ({
  table = {
    headers: ["Baseline behaviors", "Triggers", "Escalation trajectory"],
    rows: [
      [
        "Strengths, especially those that athletes may not recognize themselves",
        "How they learn best, be it visually, auditory, kinesthetically",
        "Sociability / how they make friends",
      ],
      [
        "What words girls use to describe themselves",
        "What makes them laugh / happy",
        "What makes them uncomfortable or upset",
      ],
    ],
  },
}) => {
  return (
    <>
      <Box
        width={"100%"}
        css={[
          css`
            border-left: 1px solid black;
            border-right: 1px solid black;
            border-bottom: 1px solid black;
          `,
          mqMobile,
        ]}
      >
        {table.headers.map((header, index) => (
          <Box
            key={index}
            pl={5}
            pr={6}
            css={css`
              border-top: 1px solid black;
              border-collapse: collapse;
              text-align: left;
              width: 100%;
              border-spacing: 0;
            `}
          >
            <>
              <Heading
                as={"h5"}
                fontSize={"16px"}
                lineHeight={"20px"}
                display={"block"}
                textAlign={"left"}
                mt={5}
              >
                {header}
              </Heading>
              <Ul>
                {table.rows.map((row, rowIndex) => (
                  <li>{row[index]}</li>
                ))}
              </Ul>
            </>
          </Box>
        ))}
      </Box>

      <table
        cellSpacing="0"
        css={[
          css`
            border: 1px solid black;
            width: 100%;
            table-layout: fixed;

            table,
            th,
            td {
              border: 1px solid black;
              margin: 0;
              padding: 10px;
              padding-left: 20px;
              text-align: left;
              border-spacing: 0; /* Removes the cell spacing via CSS */
              border-collapse: collapse;
            }
            th {
              font-family: "AdineuePROBoldWeb";
              text-transform: uppercase;
            }
            td {
              font-family: "AdineuePRORegularWeb";
              word-break: keep-all;
            }
          `,
          mqNonMobile,
        ]}
      >
        <thead>
          <tr>
            {table.headers.map((header, index) => (
              <th>
                <Heading
                  as={"h5"}
                  fontSize={"16px"}
                  lineHeight={"20px"}
                  display={"block"}
                  textAlign={"left"}
                >
                  {header}
                </Heading>
              </th>
            ))}
          </tr>
        </thead>
        {table.rows.map((row, index) => (
          <tr key={index}>
            {row.map((item, itemIndex) => (
              <td valign="top" key={itemIndex}>
                <Text doBreak>{item}</Text>
              </td>
            ))}
          </tr>
        ))}
      </table>
    </>
  )
}

const Page = ({ myPage = 1, mySubpage = 2 }) => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(name: { eq: "2.3HERO" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      diff1: file(name: { eq: "2.3SPOTDIFF1" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      diff2: file(name: { eq: "2.3SPOTDIFF2" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      diff2solution: file(name: { eq: "2.3SPOTDIFF2-solution" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      percent: file(name: { eq: "2.3a-ADIDAS-18percent" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const [answersClicked, setAnswersClicked] = useState(false)

  const { hero, percent, diff1, diff2, diff2solution } = data

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge="Actions speak louder than words, and behavior patterns often hint at deeper stories."
        text={
          <>
            <Text>
              It’s up to you as a coach to pay attention to the details of your
              girls’ behaviors on and off the field in order to truly understand
              each player and build the strongest relationship possible.{" "}
            </Text>
            <Text>
              So how do you initiate a dialogue with athletes, develop personal
              connections and process the information you get to apply as a
              coach?
            </Text>
          </>
        }
        image={hero}
        bg={"white"}
      />

      <InfoboxDetailed headerText={"Spot the Difference"} py={6}>
        <Flex
          flexDirection={["column", "row"]}
          mt={6}
          mb={5}
          maxWidth={"desktop"}
          mx={"auto"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box width={["100%", "50%"]} p={4} mx={"auto"}>
            <Img
              fluid={diff1.childImageSharp.fluid}
              margin={"0 auto"}
              loading={"eager"}
            ></Img>
          </Box>
          <Box width={["100%", "50%"]} p={4} mx={"auto"}>
            {answersClicked ? (
              <Img
                fluid={diff2solution.childImageSharp.fluid}
                margin={"0 auto"}
                loading={"eager"}
              ></Img>
            ) : (
              <Img
                fluid={diff2.childImageSharp.fluid}
                margin={"0 auto"}
                loading={"eager"}
              ></Img>
            )}
          </Box>
        </Flex>
        <Flex justifyContent={"center"} alignItems={"center"}>
          <Button
            mr={[0, 6]}
            px={7}
            height={"48px"}
            fontSize={["12px", "14px"]}
            lineHeight={["14px", "16px"]}
            width={["100%", "auto"]}
            onClick={() => setAnswersClicked(true)}
          >
            CHECK YOUR ANSWERS
          </Button>
        </Flex>
        <Box px={3} maxWidth={"mediumContent"} mx={"auto"} mt={6}>
          <Text
            uppercase
            fontSize={["18px", "24px"]}
            lineHeight={["27px", "36px"]}
            fontFamily={"AdineuePROBoldWeb"}
          >
            What’s the point? Well, according to leadership expert Warren
            Bennis, the best leaders are “first-class noticers.”
          </Text>
        </Box>

        <Box mt={[6, 7]} mb={[6, 6]} px={3} maxWidth={"1000px"} mx={"auto"}>
          <Heading
            as={"h2"}
            fontSize={["24px", "48px"]}
            lineHeight={["37px", "68px"]}
            background={"yellow"}
          >
            When you notice things about girls, it lets them know that you
            <br />{" "}
            <span
              css={css`
                text-decoration: underline;
              `}
            >
              see
            </span>{" "}
            them.
          </Heading>
        </Box>

        <Box px={3}>
          <Text
            uppercase
            fontSize={["18px", "24px"]}
            lineHeight={["27px", "36px"]}
            maxWidth={"mediumContent"}
            mx={"auto"}
            fontFamily={"AdineuePROBoldWeb"}
          >
            OK, so what are you noticing? And how do you notice it?
          </Text>
        </Box>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={"LOOK & LISTEN"}
        bg={"seafoam"}
        headerBackground={"transparent"}
        contentMaxWidth={"desktop"}
      >
        <Box bg={"white-panel"} mt={[5, 6]} px={[5, 5, 6]} py={[3, 6]}>
          <Flex
            maxWidth={"800px"}
            mx={"auto"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Box width={"100%"}>
              <Text>
                From an untucked elbow in a softball swing to a slight hunch in
                the shoulders in rowing, noticing is what coaches do. You can
                expand this skill beyond the sport and use it to develop strong
                relationships with your athletes.
              </Text>
            </Box>
            <Box width={"100%"}>
              <Text>
                Because it can be difficult to directly ask girls about
                themselves, you have to learn how to notice things that can be
                indicators of deeper personal stories.
              </Text>
            </Box>
            <Table />
            <Box width={"100%"}>
              <Text>
                Notice things that you can see and hear, and understand that
                there is a deeper story behind all of it. But rather than figure
                out what that story is immediately, just focus on treating
                athletes with empathy and patience, understanding that each
                individual has a full life that contributes to what you see and
                hear from them.
              </Text>
            </Box>
          </Flex>
        </Box>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={
          "Informal time is a great time to look and listen to what’s going on with your athletes. Just noticing and showing them that you see them as individuals can be very powerful."
        }
        headerBackground={"yellow"}
        py={6}
        headerMaxWidth={"desktop"}
      >
        <Flex
          flexDirection={"column"}
          maxWidth={"mediumContent"}
          mx={"auto"}
          mt={6}
        >
          <Box px={3} mb={6}>
            <Text
              uppercase
              fontSize={["20px", "32px"]}
              lineHeight={["27px", "60px"]}
              fontFamily={"AdineuePROBlackWeb"}
            >
              Noticing is the first step.
            </Text>
          </Box>
          <Box
            width={"100%"}
            mb={6}
            maxWidth={["70%", "295px", "70%"]}
            mx={"auto"}
          >
            <Img
              fluid={percent.childImageSharp.fluid}
              margin={"0 auto"}
              loading={"eager"}
            ></Img>
          </Box>
          <Text
            fontSize={["18px", "32px", "40px"]}
            lineHeight={["27px", "39px", "48px"]}
            fontFamily={"AdineuePROBlackItalicWeb"}
            color={"red"}
            mt={0}
          >
            18% of kids in a Nevada school district didn’t think an adult would
            notice if they didn’t come to school.<Source>17</Source>
          </Text>
          <Box px={3} my={5}>
            <Text
              uppercase
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "32px"]}
              fontFamily={"AdineuePROBoldWeb"}
            >
              How do you think this affects a kid’s desire to go somewhere if
              they don’t think it matters if they show up?
            </Text>
          </Box>
          <Box px={3}>
            <Text
              uppercase
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "32px"]}
              fontFamily={"AdineuePROBoldWeb"}
            >
              The foundation of your relationships with girls is{" "}
              <span
                css={css`
                  font-family: "AdineuePROBlackWeb";
                `}
              >
                seeing them
              </span>
              . Your ability to consistently show them that you see them can be
              an important motivator for them to show up.
            </Text>
          </Box>
        </Flex>
      </InfoboxDetailed>
      <Copyright />
    </Layout>
  )
}

export default withProgress(Page, 1, 2)
